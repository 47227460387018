import { Reducer } from "redux";
import * as type from "../actions/Types";
import { UserType } from "../../types/global.interface";
import { TDragItem, TRelativeDistance } from "../../types/edit.interface";
import { TSignature } from "../../types/edit.interface";

export interface UserState {
  currentUser: UserType | null;
  token: string | null;
  draggedItem: TDragItem[];
  relativeDistance: TRelativeDistance | null;
  retrievedItem: TSignature[]
 
}

export interface UserAction {
  type: string;
  payload?: any;
}

const initialState: UserState = {
  currentUser: null,
  token: "",
  draggedItem: [],
  relativeDistance: null,
  retrievedItem: []
};
const User: Reducer<UserState, UserAction> = (state = initialState, action) => {
  switch (action.type) {
    case type.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload,
      };
    case type.FETCH_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
      };

    case type.LOGOUT:
      return {
        ...state,
        currentUser: null,
        token: "",
      };
    case type.DRAGGED_ITEM:
      return {
        ...state,
        draggedItem: action.payload,
      };
      case type.RETRIEVED_ITEM:
        return {
          ...state,
          retrievedItem: action.payload,
        };
    case type.RELATIVE_DISTANCE:
      return {
        ...state,
        relativeDistance: action.payload,
      };

    default:
      return state;
  }
};

export default User;
