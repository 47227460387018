import { combineReducers, Reducer } from "redux";
import User, { UserState } from "./user";

type RootState = {
  user: UserState;
};

type RootAction = {
  type: string;
  payload?: any;
};
const appReducer: Reducer<RootState, RootAction> = combineReducers({
  user: User,
});

const rootReducer: Reducer<RootState, RootAction> = (state, action) => {
  if (action.type === "LOGOUT") {
    // storage.removeItem('persist:otherKey')
    localStorage.removeItem("persist:root");

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
