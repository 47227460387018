import { FC, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { RequireAuth } from "./utils/RequireAuth";
import "./App.css";
// import {
//   AddDcoument,
//   Completed,
//   EditDocument,
//   Expiring,
//   Login,
//   Pending,
//   Register,
//   Settings,
//   Template,
// } from "./pages";
import { Toaster } from "react-hot-toast";
import { TOASTER_PROPS } from "./lib";
// import Main from "./components/main/Main";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
//import HTML5Backend from "react-dnd-html5-backend";
// import { BackendFactory } from "dnd-core";
//import RetrieveDocument from "./components/retrieveDocument/RetrieveDocument";
//import  TouchBackend  from "react-dnd-touch-backend";
//import  MultiBackend, { TouchTransition, MouseTransition } from "react-dnd-multi-backend";

const Home = lazy(() => import('./components/home/Home'));
const Document = lazy(() => import('./components/document/Document'));
const Main = lazy(() => import('./components/main/Main'));
const RetrieveDocument = lazy(() => import('./components/retrieveDocument/RetrieveDocument'));
const AddDcoument = lazy(() => import('./components/addDocument/AddDocument'));
const Completed = lazy(() => import('./components/sentdocument/Completed'));
const EditDocument = lazy(() => import('./components/editDocument/EditDocument'));
const Expiring = lazy(() => import('./components/sentdocument/Expiring'));
const Login = lazy(() => import('./components/login/Login'));
const Pending = lazy(() => import('./components/sentdocument/Pending'));
const Register = lazy(() => import('./components/register/Register'));
const Settings = lazy(() => import('./components/settings/Settings'));
const Template = lazy(() => import('./components/template/Template'));


const App: FC = () => {
  /**
   const CustomHTML5toTouch: MultiBackendOptions = {
    backends: [
      {
        id: "html5",
        backend: HTML5Backend as any ,
        transition: MouseTransition
      },
      {
        id: "touch",
        backend: TouchBackend as any,
        options: { enableMouseEvents: true },
        preview: true,
        transition: TouchTransition,
        skipDispatchOnTransition: true
      }
    ]
  };
 */

  return (
    <div className="App w-full h-full   text-[13px] sm:text-sm">
      <Toaster {...TOASTER_PROPS} />

      <Router>
        <Suspense
          fallback={
            <div className="w-full h-full inset-0 fixed ">
              <div className="w-fit h-fit m-auto absolute inset-0">
                <div className="border-l-2 border-b-2 animate-spin rounded-full border-donkeysign h-12 w-12 "></div>
              </div>
            </div>
          }
        >
          <DndProvider options={HTML5toTouch}>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route element={<Main />}>
                <Route path="/home" element={<Home />} />
                <Route path="/document" element={<Document />} />
                <Route path="/template" element={<Template />} />
                <Route path="/completed" element={<Completed />} />
                <Route path="/expiring" element={<Expiring />} />
                <Route path="/pending" element={<Pending />} />
                <Route path="/settings" element={<Settings />} />
              </Route>
              <Route path="/add-document" element={<AddDcoument />} />
              <Route path="/edit-document" element={<EditDocument />} />
              <Route path="/sign-document" element={<RetrieveDocument />} />
            </Routes>
          </DndProvider>
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
